<template>
  <div class="vtex">
    <h1>Credenciais VTEX</h1>
    <p>Configure as informações de conexão com a loja.</p>

    <form class="default-formulario vtex-formulario" autocomplete="off">
      <h3>Informações da loja</h3>
      <div class="formulario-linha">
        <inputValidate class="formulario-item" :erro="accountNameErro">
          <label class="formulario-item-label"
            >VTEX accountname <span>*</span></label
          >
          <input
            type="text"
            name="accountName"
            v-model.trim="form.accountName"
            @blur="$v.form.accountName.$touch()"
          />
        </inputValidate>
      </div>
      <div class="formulario-linha">
        <inputValidate class="formulario-item" :erro="environmentErro">
          <label class="formulario-item-label"
            >VTEX environment <span>*</span></label
          >
          <input
            type="text"
            name="environment"
            v-model.trim="form.environment"
            @blur="$v.form.environment.$touch()"
          />
        </inputValidate>
      </div>

      <h3>Credenciais</h3>
      <div class="formulario-linha">
        <inputValidate class="formulario-item" :erro="appkeyErro">
          <label class="formulario-item-label"
            >VTEX AppKey <span>*</span></label
          >
          <input
            type="text"
            name="appkey"
            v-model.trim="form.appkey"
            @blur="$v.form.appkey.$touch()"
          />
        </inputValidate>
      </div>
      <div class="formulario-linha">
        <inputValidate class="formulario-item" :erro="apptokenErro">
          <label class="formulario-item-label"
            >VTEX AppToken <span>*</span></label
          >
          <input
            type="password"
            name="apptoken"
            v-model.trim="form.apptoken"
            @blur="$v.form.apptoken.$touch()"
          />
        </inputValidate>
      </div>
    </form>
    <div class="btns-formulario">
      <b-button
        pill
        variant="success"
        size="lg"
        :disabled="$v.form.$invalid"
        @click="aplicar"
        >Salvar
      </b-button>
    </div>
  </div>
</template>

<script>
import inputValidate from "@/components/formulario/InputValidate.vue";
import * as vuelidate from "@/plugins/vuelidate/setup";
import { requiredIf } from "vuelidate/lib/validators";
import {
  listarVtexAccount,
  cadastrarVtexAccount,
  atualizarVtexAccount,
} from "@/api/vtex";
export default {
  components: {
    inputValidate,
  },
  data() {
    return {
      form: {
        accountName: "",
        environment: "",
        appkey: "",
        apptoken: "",
      },
    };
  },
  validations: {
    form: {
      accountName: {
        required: requiredIf(() => true),
      },
      environment: {
        required: requiredIf(() => true),
      },
      appkey: {
        required: requiredIf(() => true),
      },
      apptoken: {
        required: requiredIf(() => true),
      },
    },
  },
  methods: {
    listarVtexAccount,
    cadastrarVtexAccount,
    atualizarVtexAccount,
    aplicar() {
      if (this.account) {
        this.atualizarVtexAccount(this.account.id, {
          accountname: this.form.accountName,
          environment: this.form.environment,
          vtexappkey: this.form.appkey,
          vtexapptoken: this.form.apptoken,
        });
      } else {
        this.cadastrarVtexAccount({
          accountname: this.form.accountName,
          environment: this.form.environment,
          vtexappkey: this.form.appkey,
          vtexapptoken: this.form.apptoken,
        });
      }
    },
  },
  computed: {
    accountNameErro: vuelidate.accountNameErro,
    environmentErro: vuelidate.environmentErro,
    appkeyErro: vuelidate.appkeyErro,
    apptokenErro: vuelidate.apptokenErro,
    account() {
      return this.$store.state.vtex.account;
    },
  },
  mounted() {
    this.listarVtexAccount();
  },
  watch: {
    account() {
      this.form.accountName = this.account.accountname;
      this.form.environment = this.account.environment;
      this.form.appkey = this.account.vtexappkey;
      this.form.apptoken = this.account.vtexapptoken;
    },
  },
};
</script>

<style lang="scss" scoped>
.vtex {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  .vtex-formulario {
    margin-top: 30px;
    max-width: 400px;
  }
}
</style>
