import client from "@/api";
import store from "@/store";
import { _rotaVtexAccount, _rotaVtexDb, _rotaVtexLog, _rotaVtexProduct, _rotaVtexSku } from "@/api/_caminhosApi";

export function listarVtexAccount() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/SET_ACCOUNT", null);
  client
    .get(`${_rotaVtexAccount()}`)
    .then(resp => {
      if (resp.status === 201 && resp.data.vtex_account.length > 0) {
        store.commit("vtex/SET_ACCOUNT", resp.data.vtex_account[0]);
      } else {
        store.commit("vtex/SET_ACCOUNT", {});
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function cadastrarVtexAccount(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaVtexAccount()}`, data)
    .then(() => {
      store.commit("vtex/SET_ACCOUNT_SUCCESSS", true);
    })
    .catch(erro => {
      console.log(erro);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data ? erro.response.data.message : "Erro inesperado. Informe a área técnica.",
        tipo: "erro",
      });
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function atualizarVtexAccount(id, data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .put(`${_rotaVtexAccount()}/${id}`, data)
    .then(() => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Atualizado com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log(erro);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data ? erro.response.data.message : "Erro inesperado. Informe a área técnica.",
        tipo: "erro",
      });
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function sincronizaProdutosSkus() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaVtexDb()}`)
    .then(resp => {
      if (resp.status === 201) {
        store.commit("interacoes/SET_MODAL", {
          ativado: true,
          mensagem: "Sincronização iniciada. Isso pode levar até 120 minutos.",
          tipo: "sucesso",
        });
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarLogs(page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/RESET_LOGS", null);
  client
    .get(`${_rotaVtexLog()}?page=${page}`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vtex/SET_LOGS", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarProdutos(page, qtd) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/RESET_PRODUCTS", null);
  client
    .get(`${_rotaVtexProduct()}?page=${page}&per_page=${qtd}`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vtex/SET_PRODUCTS", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarSkus(page, qtd) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/RESET_PRODUCTS", null);
  client
    .get(`${_rotaVtexSku()}?page=${page}&per_page=${qtd}`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vtex/SET_PRODUCTS", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
