
export function accountNameErro() {
    let erro = "";
    const accountName = this.$v.form.accountName;
    if (!accountName.$dirty) {
      return erro;
    }
    if (!accountName.required) {
      erro = "Obrigatório";
    }
    return erro;
}
  
export function environmentErro() {
    let erro = "";
    const environment = this.$v.form.environment;
    if (!environment.$dirty) {
      return erro;
    }
    if (!environment.required) {
      erro = "Obrigatório";
    }
    return erro;
}
  
export function appkeyErro() {
  let erro = "";
  const appkey = this.$v.form.appkey;
  if (!appkey.$dirty) {
    return erro;
  }
  if (!appkey.required) {
    erro = "Obrigatório";
  }
  return erro;
}

export function apptokenErro() {
  let erro = "";
  const apptoken = this.$v.form.apptoken;
  if (!apptoken.$dirty) {
    return erro;
  }
  if (!apptoken.required) {
    erro = "Obrigatório";
  }
  return erro;
}
